import { reactive } from "vue";
import { io } from "socket.io-client";

export const state = reactive({
  connected: false,
  fooEvents: [],
  barEvents: [],
});

// Fetch WebSocket URL from env
const URL = process.env.VUE_APP_NODE_SOCKETIO || "wss://localhost:3000";

export const socket = io(URL, {
  secure: true,
  rejectUnauthorized: false, // Bypass SSL verification (only for local development)
});

const joinedRooms = new Set();

socket.on("connect", () => {
  state.connected = true;

  let userData = localStorage.getItem("user_data")
    ? JSON.parse(localStorage.getItem("user_data"))
    : null;
  let userId = userData?.id ?? null;
  let selectedCountry = localStorage.getItem("selectedCountry") ?? "us";

  if (!userId) return;

  const roomsToJoin = ["public", selectedCountry, userId];

  setTimeout(() => {
    roomsToJoin.forEach((room) => {
      if (!joinedRooms.has(room)) {
        socket.emit("joinRoom", { room, userId });
        joinedRooms.add(room);
      }
    });
  }, 300);
});

socket.on("disconnect", () => {
  state.connected = false;

  joinedRooms.clear();
  console.warn("Socket disconnected, attempting to reconnect...");

  socket.once("connect", () => {
    console.log("Reconnected! Rejoining rooms...");
    let userData = localStorage.getItem("user_data")
      ? JSON.parse(localStorage.getItem("user_data"))
      : null;
    let userId = userData?.id ?? null;
    let selectedCountry = localStorage.getItem("selectedCountry") ?? "us";

    if (!userId) return;

    const roomsToJoin = ["public", selectedCountry, userId];

    setTimeout(() => {
      roomsToJoin.forEach((room) => {
        if (!joinedRooms.has(room)) {
          socket.emit("joinRoom", { room, userId });
          joinedRooms.add(room);
        }
      });
    }, 300);
  });
});
